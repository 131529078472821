import React from "react";
import { graphql, withPrefix, Link } from "gatsby";
import Helmet from "react-helmet";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import logo from "../images/logo.png";
import ContactForm from "../components/ContactForm";
import AnimatedLogo from "../components/AnimatedLogo"
import partnerCH from '../images/partner-logo-ch.png'
import partnerPCBA from '../images/partner-logo-pcba.png'
import partnerSafer from '../images/partner-logo-satei.png'
import partnerVCA from '../images/partner-logo-vca.png'

const Home = (props) => {
  const markdown = props.data.allMarkdownRemark.edges;
  const json = props.data.allFeaturesJson.edges;
  const path = props.uri;

  return (
    <Layout bodyClass="page-home" path={path}>
      <SEO title="Home" keywords={['Central East', 'Central East Corporation', 'Central East Corporation LTD']}/>
      <Helmet>
        <meta
          name="description"
          content="Central East Corporation Ltd is a Hong Kong based sourcing organization and a trading company with over 3 years of experience in purchasing and sourcing in Asia."
        />
      </Helmet>

      <div className="hero">
        <div className="intro pb-4">
          <div className="container">
            <div className="row hero-logo-wrap">
              <AnimatedLogo />
              <img alt="CEC Logo" src={logo} className="d-block d-md-none" />
            </div>
          </div>
        </div>
        <AnchorLink to="/#services" title="" className="button btn-lg btn-hero">
          Learn More
        </AnchorLink>
        <div className="hero-divider" />
      </div>

      <div className="container pt-8 pt-md-8" id="services">
        <div className="row justify-content-evenly">
          <div className="col-12">
            <h2 className="text-center title-3 mb-3">Our Services</h2>
          </div>
          {markdown.map((edge) => (
            <div
              key={edge.node.frontmatter.path}
              className="col-12 col-md-4 mb-2"
              id={edge.node.frontmatter.path}
              data-sal="zoom-in"
              data-sal-duration="800"
            >
              <div className="card-two service service-teaser">
                <div className="card-image">
                  <img
                    src={edge.node.frontmatter.imageLight}
                    alt={`service ${edge.node.title}`}
                  />
                </div>
                <h2 className="card-title">{edge.node.frontmatter.title}</h2>
                <div className="card-content">
                  <p>{edge.node.frontmatter.subtitle}</p>
                </div>
                <div className="text-center">
                  <AnchorLink
                    to={`${edge.node.frontmatter.path}`}
                    title={`${edge.node.frontmatter.title}`}
                    className="button button-primary btn mt-2"
                    stripHash
                  >
                    Learn More
                  </AnchorLink>
                </div>
              </div>
            </div>
          ))}
          <div className="col-12 text-center">
            <Link to="/services" className="button button-primary btn-lg mt-2">
              View All Services
            </Link>
          </div>
        </div>
      </div>

      <div className="about-section mt-2 mt-md-7">
        <div className="about-divider" />
        <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
          <div className="row justify-content-center">
            <div className="col-12">
              <h2 className="text-center text-white title-3 mb-4">About Us</h2>
            </div>
            <div className="col-12 mb-md-5 text-white text-justify">
              <p>
                Central East Corporation Ltd is a Hong Kong based sourcing
                organization and a trading company with over 3 years of
                experience in purchasing and sourcing in Asia.
              </p>
              <p>
                With our determination and quest for excellence, we have evolved
                in electronics and IT products importation and exportation,
                software development and cloud computing services over the
                years, gradually adjusting our business model from a trading
                company to a sourcing service and solution provider for overseas
                customers.
              </p>
              <p>
                Our success is contributed to its ability to understand the
                customer’s requirement and need. We strive to bring the best
                solution to every single client of ours.
              </p>
              <p>
                Based in Hong Kong, Central East Corporation commits to provide
                the innovative products for the right customers at the right
                time through its professional services, such as manufacturer
                sourcing, product sourcing, product R&#38;D, quality control
                measures, project management and logistics service both in HK
                and mainland China. Till now, we are proud of that we have
                attained a high reputation for our service quality and customer
                satisfaction.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="partners-section">
        <div className="partners-divider" />
        <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
          <div className="row justify-content-center">
            <div className="col-12">
              <h2 className="text-center title-3 mb-4">Our noteworthy partners</h2>
            </div>
            <div className="col-12 mb-md-5 d-flex flex-column flex-md-row justify-content-around">
              <div className="partner-card">
                <a href="http://www.xinchuanghui.com/en/" target="_blank" rel="external nofollow noreferrer">
                  <img src={partnerCH} alt="partner logo"/>
                </a>
              </div>
              <div className="partner-card">
                <a href="https://www.pcbastore.com/" target="_blank" rel="external nofollow noreferrer">
                  <img src={partnerPCBA} alt="partner logo"/>
                </a>
              </div>
              <div className="partner-card">
                <a href="https://www.safer.net.cn/" target="_blank" rel="external nofollow noreferrer">
                  <img src={partnerSafer} alt="partner logo"/>
                </a>
              </div>
              <div className="partner-card">
                <a href="https://www.vcalaser.com/" target="_blank" rel="external nofollow noreferrer">
                  <img src={partnerVCA} alt="partner logo"/>
                </a>
              </div>              
            </div>
          </div>
        </div>
      </div>

      <div className="feature-section">
        <div className="container pb-md-5 pt-md-7">
          <div className="row justify-content-center">
            <div className="d-flex flex-wrap col-12 mt-4">
              {json.map((edge) => (
                <div
                  key={edge.node.id}
                  className="col-12 col-lg-4 mb-2"
                  data-sal="zoom-in"
                  data-sal-duration="800"
                >
                  <div className="feature">
                    <div className="feature-content">
                      {edge.node.image && (
                          <div className="feature-image">
                            <img
                              src={withPrefix(edge.node.image)}
                              alt={`feature ${edge.node.title}`}
                            />
                          </div>
                        )}
                      <h2 className="feature-title">{edge.node.title}</h2>
                      <div className="feature-text">{edge.node.description}</div>
                    </div>                    
                    </div>                    
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="contact-section">
        <div className="container pt-5 pb-5 pt-md-5 pb-md-5">
          <div className="row justify-content-center">
            <div className="col-12">
              <h2 className="text-center title-3 mb-4">Contact Us</h2>
            </div>
            <div className="col-10 mb-md-5">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services/" } }
      sort: { fields: [frontmatter___no], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            subtitle
            imageLight
            imageDark
          }
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
        }
      }
    }
  }
`;

export default Home;
