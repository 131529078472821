import React from 'react'

const AnimatedLogo = () => {
    return (
        <div className="animated-logo d-none d-md-block">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                id="svg1164"
                width="679.543"
                height="250"
                version="1.1"
                viewBox="0 0 179.796 63.867"
                >
                <defs id="defs1158">
                    <linearGradient
                    id="linearGradient3241"
                    x1="127.548"
                    x2="127.548"
                    y1="236.95"
                    y2="252.58"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient1076-3"
                    ></linearGradient>
                    <linearGradient id="linearGradient1076-3">
                    <stop
                        id="stop1072"
                        offset="0"
                        stopColor="#f9c48e"
                        stopOpacity="1"
                    ></stop>
                    <stop
                        id="stop1074"
                        offset="1"
                        stopColor="#f28615"
                        stopOpacity="1"
                    ></stop>
                    </linearGradient>
                    <path
                    id="rect924-2-23-0"
                    d="M30.237 234.494H352.118V283.029H30.237z"
                    ></path>
                    <path id="rect1046" d="M30.237 234.494H352.118V283.029H30.237z"></path>
                    <path
                    id="rect924-2-2-5-1"
                    d="M30.237 234.494H352.118V283.029H30.237z"
                    ></path>
                    <path id="rect1072" d="M30.237 234.494H352.118V283.029H30.237z"></path>
                    <linearGradient
                    id="linearGradient3229"
                    x1="267.951"
                    x2="280.434"
                    y1="82.417"
                    y2="153.28"
                    gradientTransform="matrix(.5156 0 0 .5156 104.434 -30.486)"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient1076-3"
                    ></linearGradient>
                    <linearGradient
                    id="linearGradient1457-6-0-1-2"
                    x1="267.951"
                    x2="280.434"
                    y1="82.417"
                    y2="153.28"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient1076-3"
                    ></linearGradient>
                    <linearGradient
                    id="linearGradient3239"
                    x1="267.951"
                    x2="280.434"
                    y1="82.417"
                    y2="153.28"
                    gradientTransform="translate(-398.988 678.478) scale(1.94875)"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient1076-3"
                    ></linearGradient>
                    <clipPath id="clipPath3294-9-2" clipPathUnits="userSpaceOnUse">
                    <path
                        id="path3296-2-6"
                        fill="none"
                        stroke="#f9c48e"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="1"
                        strokeWidth="11.693"
                        d="M49.4 1210.27A109.18 110.1 32.54 0196.629 1095a109.18 110.1 32.54 01123.83 1.953 109.18 110.1 32.54 0141.832 116.676"
                        display="inline"
                    ></path>
                    </clipPath>
                    <linearGradient
                    id="linearGradient1840"
                    x1="127.548"
                    x2="127.548"
                    y1="236.95"
                    y2="252.58"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient1076-3"
                    ></linearGradient>
                    <linearGradient
                    id="linearGradient1842"
                    x1="267.951"
                    x2="280.434"
                    y1="82.417"
                    y2="153.28"
                    gradientTransform="matrix(.5156 0 0 .5156 -103.863 -.728)"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient1076-3"
                    ></linearGradient>
                    <linearGradient
                    id="linearGradient1844"
                    x1="267.951"
                    x2="280.434"
                    y1="82.417"
                    y2="153.28"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient1076-3"
                    ></linearGradient>
                    <linearGradient
                    id="linearGradient1846"
                    x1="267.951"
                    x2="280.434"
                    y1="82.417"
                    y2="153.28"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient1076-3"
                    ></linearGradient>
                    <linearGradient
                    id="linearGradient1848"
                    x1="267.951"
                    x2="280.434"
                    y1="82.417"
                    y2="153.28"
                    gradientTransform="matrix(.5156 0 0 .5156 104.434 -30.486)"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient1076-3"
                    ></linearGradient>
                    <clipPath id="clipPath3294-9-2-7" clipPathUnits="userSpaceOnUse">
                    <path
                        id="path3296-2-6-5"
                        fill="none"
                        stroke="#f9c48e"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="1"
                        strokeWidth="11.693"
                        d="M49.4 1210.27A109.18 110.1 32.54 0196.629 1095a109.18 110.1 32.54 01123.83 1.953 109.18 110.1 32.54 0141.832 116.676"
                        display="inline"
                    ></path>
                    </clipPath>
                    <filter
                    id="filter2070"
                    width="1.107"
                    height="1.086"
                    x="-0.053"
                    y="-0.043"
                    colorInterpolationFilters="sRGB"
                    >
                    <feGaussianBlur
                        id="feGaussianBlur2072"
                        stdDeviation="1.105"
                    ></feGaussianBlur>
                    </filter>
                    <linearGradient
                    id="linearGradient1840-9"
                    x1="127.548"
                    x2="127.548"
                    y1="236.95"
                    y2="252.58"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient1076-3"
                    ></linearGradient>
                    <path
                    id="rect924-2-23-0-7"
                    d="M30.237 234.494H352.118V283.029H30.237z"
                    ></path>
                    <path id="rect2087" d="M30.237 234.494H352.118V283.029H30.237z"></path>
                    <path
                    id="rect924-2-2-5-1-9"
                    d="M30.237 234.494H352.118V283.029H30.237z"
                    ></path>
                    <path id="rect2113" d="M30.237 234.494H352.118V283.029H30.237z"></path>
                    <linearGradient
                    id="linearGradient2193"
                    x1="127.548"
                    x2="127.548"
                    y1="236.95"
                    y2="252.58"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient1076-3"
                    ></linearGradient>
                    <filter
                    id="filter2195"
                    width="1.065"
                    height="1.182"
                    x="-0.033"
                    y="-0.091"
                    colorInterpolationFilters="sRGB"
                    >
                    <feGaussianBlur
                        id="feGaussianBlur2197"
                        stdDeviation="1.609"
                    ></feGaussianBlur>
                    </filter>
                    <filter
                    id="filter2359"
                    width="1.099"
                    height="1.093"
                    x="-0.049"
                    y="-0.047"
                    colorInterpolationFilters="sRGB"
                    >
                    <feGaussianBlur
                        id="feGaussianBlur2361"
                        stdDeviation="0.897"
                    ></feGaussianBlur>
                    </filter>
                    <filter
                    id="filter2387"
                    width="1.096"
                    height="1.096"
                    x="-0.048"
                    y="-0.048"
                    colorInterpolationFilters="sRGB"
                    >
                    <feGaussianBlur
                        id="feGaussianBlur2389"
                        stdDeviation="0.825"
                    ></feGaussianBlur>
                    </filter>
                    <path
                    id="rect924-2-23-0-2"
                    d="M30.237 234.494H352.118V283.029H30.237z"
                    ></path>
                    <path id="rect2404" d="M30.237 234.494H352.118V283.029H30.237z"></path>
                    <path
                    id="rect924-2-2-5-1-2"
                    d="M30.237 234.494H352.118V283.029H30.237z"
                    ></path>
                    <path id="rect2430" d="M30.237 234.494H352.118V283.029H30.237z"></path>
                    <linearGradient
                    id="linearGradient2509"
                    x1="127.548"
                    x2="127.548"
                    y1="236.95"
                    y2="252.58"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient1076-3"
                    ></linearGradient>
                    <path
                    id="rect924-2-23-0-1"
                    d="M30.237 234.494H352.118V283.029H30.237z"
                    ></path>
                    <path id="rect2526" d="M30.237 234.494H352.118V283.029H30.237z"></path>
                    <linearGradient
                    id="linearGradient2575"
                    x1="127.548"
                    x2="127.548"
                    y1="236.95"
                    y2="252.58"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient1076-3"
                    ></linearGradient>
                </defs>
                <g id="layer1" transform="translate(-12.636 -40.294)">
                    <g
                    id="text-shadow"
                    fill="#000"
                    fillOpacity="0.6"
                    stroke="none"
                    filter="url(#filter2195)"
                    fontFamily="Alcubierre"
                    fontStretch="normal"
                    fontStyle="normal"
                    fontVariant="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    opacity="1"
                    transform="translate(-208.297 -78.417)"
                    wordSpacing="0"
                    >
                    <text
                        id="text922-7-3-4-6"
                        display="inline"
                        fontSize="25.4"
                        transform="translate(250.427 -107.047)"
                        xmlSpace="preserve"
                        style={{
                        lineHeight: "1.25",
                        InkscapeFontSpecification: "Alcubierre",
                        whiteSpace: "pre",
                        }}
                    >
                        <tspan x="30.236" y="256.719">
                        <tspan>Central East</tspan>
                        </tspan>
                    </text>
                    <text
                        transform="translate(276.878 -84.056)"
                        style={{
                        lineHeight: "1.25",
                        InkscapeFontSpecification: "Alcubierre",
                        whiteSpace: "pre",
                        }}
                        id="text922-7-9-1-9-0"
                        display="inline"
                        fontSize="19.756"
                        xmlSpace="preserve"
                    >
                        <tspan x="30.236" y="251.78">
                        <tspan>Corporation</tspan>
                        </tspan>
                    </text>
                    </g>
                    <g
                    id="text"
                    fillOpacity="1"
                    stroke="none"
                    fontFamily="Alcubierre"
                    fontStretch="normal"
                    fontStyle="normal"
                    fontVariant="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="translate(-208.297 -79.476)"
                    wordSpacing="0"
                    >
                    <text
                        id="text922-7-3-4"
                        fill="url(#linearGradient1840)"
                        display="inline"
                        fontSize="25.4"
                        transform="translate(250.427 -107.047)"
                        xmlSpace="preserve"
                        style={{
                        lineHeight: "1.25",
                        InkscapeFontSpecification: "Alcubierre",
                        whiteSpace: "pre",
                        }}
                    >
                        <tspan x="30.236" y="256.719">
                        <tspan>Central East</tspan>
                        </tspan>
                    </text>
                    <text
                        transform="translate(276.878 -84.056)"
                        style={{
                        lineHeight: "1.25",
                        InkscapeFontSpecification: "Alcubierre",
                        whiteSpace: "pre",
                        }}
                        id="text922-7-9-1-9"
                        fill="url(#linearGradient3241)"
                        display="inline"
                        fontSize="19.756"
                        xmlSpace="preserve"
                    >
                        <tspan x="30.236" y="251.78">
                        <tspan>Corporation</tspan>
                        </tspan>
                    </text>
                    </g>
                    <path
                    id="globe-outer-shadow"
                    fill="none"
                    stroke="#000"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeOpacity="0.6"
                    strokeWidth="3.609"
                    d="M22.693 69.171a20.621 20.621 0 0120.644-20.558 20.621 20.621 0 0120.598 20.604 20.621 20.621 0 01-20.563 20.638"
                    display="inline"
                    filter="url(#filter2387)"
                    opacity="0.5"
                    ></path>
                    <path
                    id="globe-outer"
                    fill="none"
                    stroke="url(#linearGradient1842)"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="3.609"
                    d="M22.693 69.171a20.621 20.621 0 0120.644-20.558 20.621 20.621 0 0120.598 20.604 20.621 20.621 0 01-20.563 20.638"
                    display="inline"
                    ></path>
                    <g
                    fill="none"
                    stroke="#000"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeOpacity="0.6"
                    strokeWidth="6.21"
                    display="inline"
                    filter="url(#filter2359)"
                    opacity="0.5"
                    transform="matrix(.5156 0 0 .5156 -10.825 -7.334)"
                    >
                    <path
                        id="globe-inner-shadow"
                        d="M125.602 158.817a23.04 23.04 0 01-24.063 12.462 23.04 23.04 0 01-19.275-19.048 23.04 23.04 0 0112.176-24.209 23.04 23.04 0 0126.784 4.118"
                    ></path>
                    <path
                        id="globe-inner-shadow"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        d="M119.976 128.986L87.83 162"
                    ></path>
                    </g>
                    <g
                    fill="none"
                    stroke="url(#linearGradient1457-6-0-1-2)"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="6.21"
                    display="inline"
                    transform="matrix(.5156 0 0 .5156 -10.825 -7.334)"
                    >
                    <path
                        id="globe-inner"
                        stroke="url(#linearGradient1844)"
                        d="M125.602 158.817a23.04 23.04 0 01-24.063 12.462 23.04 23.04 0 01-19.275-19.048 23.04 23.04 0 0112.176-24.209 23.04 23.04 0 0126.784 4.118"
                    ></path>
                    <path
                        id="globe-inner"
                        stroke="url(#linearGradient1846)"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        d="M119.976 128.986L87.83 162"
                    ></path>
                    </g>
                    <g
                    id="g2984-1-3"
                    fill="none"
                    stroke="#000"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="0.604"
                    filter="url(#filter2070)"
                    opacity="0.5"
                    transform="translate(-208.297 30.816)"
                    >
                    <path
                        id="path3192-1-1-5"
                        strokeWidth="3.094"
                        d="M272.14 59.971a29.13 28.887 0 01-32.431 5.871 29.13 28.887 0 01-17.188-27.898 29.13 28.887 0 0120.071-25.935"
                        display="inline"
                    ></path>
                    <path
                        id="path3190-2-5-6"
                        strokeWidth="3.094"
                        d="M251.611 73.386v-4.907"
                        display="inline"
                    ></path>
                    <path
                        id="path1057-0-75-94-9-2"
                        strokeWidth="11.693"
                        d="M139.001 1076.69h36.54"
                        clipPath="url(#clipPath3294-9-2-7)"
                        display="inline"
                        transform="matrix(.26458 0 0 .26458 210 -212.018)"
                    ></path>
                    </g>
                    <g
                    id="g2984-1"
                    fill="none"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    transform="translate(-208.297 29.758)"
                    >
                    <path
                        id="path3192-1-1"
                        stroke="url(#linearGradient1848)"
                        strokeWidth="3.094"
                        d="M272.14 59.971a29.13 28.887 0 01-32.431 5.871 29.13 28.887 0 01-17.188-27.898 29.13 28.887 0 0120.071-25.935"
                        display="inline"
                    ></path>
                    <path
                        id="path3190-2-5"
                        stroke="url(#linearGradient3229)"
                        strokeWidth="3.094"
                        d="M251.611 73.386v-4.907"
                        display="inline"
                    ></path>
                    <path
                        id="path1057-0-75-94-9"
                        stroke="url(#linearGradient3239)"
                        strokeWidth="11.693"
                        d="M139.001 1076.69h36.54"
                        clipPath="url(#clipPath3294-9-2)"
                        display="inline"
                        transform="matrix(.26458 0 0 .26458 210 -212.018)"
                    ></path>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default AnimatedLogo
