import React from "react"
import Company from '../images/company.png'
import Location from '../images/location.png'
import Email from '../images/email.png'

const ContactForm = () => {
    return (
      <div className="contact-wrap">
        <div className="contact-info">
          <div className="contact-item">
            <div className="contact-item-img-wrap">
            <img src={Company} alt="company"/></div>
            <h2>CENTRAL EAST CORPORATION LIMITED</h2>
          </div>
          <div className="contact-item">
          <div className="contact-item-img-wrap">
            <img src={Location} alt="location"/>
            </div>
            <h2>Suite D, 9/F., Amtel Building 148 Des Voex Road, <br/> Central, Hong Kong</h2>
          </div>
          <div className="contact-item">
            <div className="contact-item-img-wrap">
            <img src={Email} alt="email"/>
            </div>
            <a href="email:office@centraleastcorp.hk"><h2>office@centraleastcorp.hk</h2></a>
          </div>
        </div>
      </div>      
    );
}

export default ContactForm;
